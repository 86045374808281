import BackgroundImage from 'gatsby-background-image';
import PropTypes from 'prop-types';
import React from 'react';

const PreviewCompatibleBackgroundImage = ({
  imageInfo, imageClasses, imageStyle, children, fadeIn
}) => {
  const { childImageSharp, image } = imageInfo;

  if (!!image && !!image.childImageSharp) {
    return (
      <BackgroundImage className={ imageClasses } style={ imageStyle } fluid={ image.childImageSharp.fluid } fadeIn={ fadeIn }>{children}</BackgroundImage>
    );
  }

  if (childImageSharp) {
    return <BackgroundImage className={ imageClasses } style={ imageStyle } fluid={ childImageSharp.fluid } fadeIn={ fadeIn }>{children}</BackgroundImage>;
  }

  const previewStyles = {
    ...imageStyle,
    backgroundImage: `url(${image.url})`,
    backgroundSize: 'cover'
  };

  if (image) {
    return (
      <div className={ imageClasses } style={ previewStyles }>
        {children}
      </div>
    );
  }

  return null;
};

PreviewCompatibleBackgroundImage.propTypes = {
  imageInfo: PropTypes.shape({
    childImageSharp: PropTypes.object,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    style: PropTypes.object,
  }).isRequired,
  imageClasses: PropTypes.string.isRequired,
  imageStyle: PropTypes.objectOf(PropTypes.string),
  children: PropTypes.element,
  fadeIn: PropTypes.bool
};

PreviewCompatibleBackgroundImage.defaultProps = {
  imageStyle: {},
  children: undefined,
  fadeIn: false
};

export default PreviewCompatibleBackgroundImage;