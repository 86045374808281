import { mdiChevronDown } from '@mdi/js';
import Icon from '@mdi/react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Link as ScrollLink } from 'react-scroll';

import PreviewCompatibleBackgroundImage from '../components/PreviewCompatibleBackgroundImage';

const HeroSection = ({
  title, subtitle, image, actionTitle, actionRoute, heightStyle, hasCTA
}) => (
  <PreviewCompatibleBackgroundImage
    imageInfo={{ image }}
    imageClasses="vw-100"
    imageStyle={{
      backgroundColor: 'rgba(0,0,0,0.4)',
      backgroundBlendMode: 'overlay',
      backgroundPosition: 'center',
      height: heightStyle
    }}
  >
    {
      hasCTA ? (
        <>
          <Container style={{ height: '100%' }}>
            <Row noGutters style={{ height: '100%', alignItems: 'center' }}>
              <Col className="text-left" xs="12" sm="10" md="7" lg="7" xl="7">
                <h1 className="my-2 text-white">{title}</h1>
                <p className="h4 font-weight-light my-2 text-white">{subtitle}</p>
                <Link to={ actionRoute } className="btn btn-primary btn-lg my-2 text-white">{actionTitle}</Link>
              </Col>
            </Row>
          </Container>
        </>
      ) : null
    }
  </PreviewCompatibleBackgroundImage>
);


HeroSection.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.objectOf(PropTypes.object).isRequired,
  actionTitle: PropTypes.string,
  actionRoute: PropTypes.string,
  heightStyle: PropTypes.string,
  hasCTA: PropTypes.bool
};

HeroSection.defaultProps = {
  title: '',
  subtitle: '',
  actionTitle: '',
  actionRoute: '/',
  heightStyle: '100vh',
  hasCTA: false
};

export default HeroSection;